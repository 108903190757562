const CLIENT_ID = process.env.CLIENT_ID || '0oablf12qy6Y5hLiF357';
const ISSUER = process.env.ISSUER || 'https://okta.syvollabs.com/oauth2/default';
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const REDIRECT_URI = `${window.location.origin}/login/callback`;
let USE_INTERACTION_CODE = false;
if (process.env.USE_INTERACTION_CODE === 'true') {
  USE_INTERACTION_CODE = true;
}

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    useInteractionCode: USE_INTERACTION_CODE,
  },
  resourceServer: {
    messagesUrl: 'http://localhost:8000/api/messages',
  },
};
